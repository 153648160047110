import { FC } from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';
import { classNames } from 'src/lib';

type AlignmentOption = {
  icon: FC<{ className: string }>;
  value: string;
};

interface AlignmentSelectionGroupProps extends UseControllerProps {
  options: AlignmentOption[];
  label?: string;
}

export const AlignmentSelectionGroup: FC<AlignmentSelectionGroupProps> = ({
  name,
  options,
  label,
}) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col justify-center space-y-1">
      {label && <h3 className="text-sm font-medium text-text-dark">{label}</h3>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className="flex space-x-2">
            {options.map((option) => (
              <button
                key={option.value}
                className={classNames(
                  'rounded border  p-1',
                  value === option.value ? 'border-text-medium   bg-gray-50 ' : 'border-text-light'
                )}
                onClick={() => onChange(option.value)}
              >
                <option.icon
                  className={classNames(
                    value === option.value ? 'h-6 w-6 text-text-dark' : 'h-6 w-6 text-text-medium'
                  )}
                />
              </button>
            ))}
          </div>
        )}
      />
    </div>
  );
};
