import { useMutation } from '@redwoodjs/web';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { UPDATE_USER_MUTATION } from 'src/graphql/mutations';
import { useDebounce } from 'src/hooks';
import { UpdateUser, UpdateUserVariables } from 'types/graphql';
import { SettingsItem } from '../SettingsComponents';
import { classNames } from 'src/lib';

export const textInputClasses =
  'min-w-[150px] rounded-xl border border-gray-300 pl-3 py-2 text-text-veryDark placeholder:text-text-medium focus:border-primary-dark focus:outline-none pr-5';

type DebouncedInputProps = {
  label: string;
  name: string;
  initialValue?: string;
  placeholder?: string;
};

export const DebouncedInput: FC<DebouncedInputProps> = ({
  label,
  name,
  initialValue = '',
  placeholder,
}) => {
  const [state, setState] = useState(initialValue);

  const [updateUser, { loading }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER_MUTATION
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState(e.target.value);
  };

  const debouncedText = useDebounce(state, 1000);

  useEffect(() => {
    if (debouncedText && debouncedText !== initialValue) {
      updateUser({
        variables: {
          input: {
            [name]: debouncedText,
          },
        },
      });
    }
  }, [debouncedText]);

  return (
    <SettingsItem
      label={label}
      value=""
      labelClassName="text-base font-medium"
      className="max-w-[578px]"
    >
      <input
        onChange={onChange}
        value={state}
        placeholder={placeholder}
        className={classNames(
          textInputClasses,
          'w-full min-w-[253px] max-w-[400px] disabled:cursor-not-allowed disabled:text-text-medium'
        )}
        name={name}
        disabled={loading}
      />
    </SettingsItem>
  );
};
