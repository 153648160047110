// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const DOCUMENT_FIELDS_FRAGMENT = gql`
  fragment DocumentFields on Document {
    ... on General {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobSocialPost {
      id
      title
      userId
      markup
      jobId
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobAdvert {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobSnapshot {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobReferenceCheck {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobInterviewQuestions {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobBooleanSearch {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobInMail {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on JobEmail {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }

    ... on CandidateSnapshot {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      candidateId
      title
      owner {
        id
        name
        email
      }
    }

    ... on CandidateIntroduction {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      candidateId
      title
      owner {
        id
        name
        email
      }
    }

    ... on CandidateCv {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      candidateId
      title
      owner {
        id
        name
        email
      }
    }

    ... on CompanySnapshot {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      companyId
      owner {
        id
        name
        email
      }
    }

    ... on CompanyIntroduction {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      companyId
      owner {
        id
        name
        email
      }
    }

    ... on JobInterviewPreparation {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }
    ... on ClientProposal {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        clientCompanyName
        yourCompanyName
        clientCompanyUSPs
        yourCompanyUSPs
      }
      owner {
        id
        name
        email
      }
    }

    ... on BlogPost {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        industry
        targetAudience
        topic
        type
        informationToInclude
      }
      owner {
        id
        name
        email
      }
    }

    ... on BlogPostIdeas {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        industry
        candidates
        clients
      }
      owner {
        id
        name
        email
      }
    }
    ... on MarketingStrategy {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        industry
        targetCandidates
        targetClients
      }
      owner {
        id
        name
        email
      }
    }
    ... on TestimonialRequest {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        candidateName
        companyName
        recipient
        recipientName
        duration
        servicesProvided
      }
      owner {
        id
        name
        email
      }
    }

    ... on ClientOutreach {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      owner {
        id
        name
        email
      }
    }
  }
`;
