import { FC, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { RichTextEditor } from '../../components/RichTextEditor';
import { convertMarkdownToHtml } from 'src/lib';
import { InsetTabs } from 'src/components/InsetTabs';

type Props = {
  content?: string;
  loading?: boolean;
};

export const OutputEditor: FC<Props> = ({ loading, content }) => {
  const htmlContent = content ? convertMarkdownToHtml(content) : '';
  const [selectedTab, setSelectedTab] = useState<'Raw' | 'Markdown'>('Raw');

  const handleTabChange = (tab: 'Raw' | 'Markdown') => {
    setSelectedTab(tab);
  };

  return (
    <div className="flex h-full flex-1 flex-col overflow-auto border border-gray-300 px-6 py-3">
      <p className="py-3 text-lg font-bold text-text-dark">Output</p>
      {loading ? (
        <Spinner />
      ) : (
        <InsetTabs
          tabs={['Raw', 'Markdown']}
          onTabChange={handleTabChange}
          selectedTab={selectedTab}
        />
      )}

      <div className="mt-2 flex-1 overflow-auto">
        {selectedTab === 'Raw' ? (
          <div className="prose max-h-full overflow-auto">
            <RichTextEditor content={content} editable={false} hideMenuBar={true} disableMinWidth />
          </div>
        ) : (
          <div className="prose max-h-full overflow-auto prose-code:bg-gray-100 prose-code:p-1 prose-code:text-black prose-pre:bg-gray-100 prose-pre:p-1 prose-pre:text-black">
            <RichTextEditor
              content={htmlContent}
              editable={false}
              hideMenuBar={true}
              disableMinWidth
            />
          </div>
        )}
      </div>
    </div>
  );
};
