import { FC, useEffect, useRef } from 'react';
import { useForm } from '@redwoodjs/forms';
import { PDFViewer } from '@react-pdf/renderer';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  DELETE_LOGO_IMAGE_MUTATION,
  UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION,
  UPSERT_LOGO_IMAGE_MUTATION,
} from 'src/graphql/mutations';
import { GET_BRANDED_DOCUMENT_CONFIG_QUERY } from 'src/graphql/queries';
import {
  DeleteLogoImage,
  DeleteLogoImageVariables,
  GetBrandedDocumentConfig,
  GetBrandedDocumentConfigVariables,
  UpdateBrandedDocumentConfig,
  UpdateBrandedDocumentConfigVariables,
  UpsertLogoImage,
  UpsertLogoImageVariables,
} from 'types/graphql';

import { Link, SelectField, TextAreaField } from 'src/components';
import { Form } from 'src/components/Form';
import { AlignmentSelectionGroup } from './AlignmentSelectionGroup';
import { FileInput } from './FileInput';
import { FontSelectionGroup } from './FontSelectionGroup';
import { useBoolean, useDebounce, useFileUpload, usePageClasses } from 'src/hooks';
import { BrandingFormValues, brandingFormSchema } from './brandingFormSchema';
import {
  DUMMY_PDF_PREVIEW_DATA,
  alignmentOptions,
  fontOptions,
  logoSizeOptions,
  marginOptions,
} from './constants';
import { navigate, routes } from '@redwoodjs/router';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import BrandedPdfDocument from 'src/components/PdfDocument/BrandedPdfDocument';
import {
  DEFAULT_BRANDING_CONFIG,
  convertHtmlToReactPdfContent,
} from 'src/components/PdfDocument/pdf';
import { Spinner } from '../../../components/Spinner';
import { notNullish } from '../../../lib/guards';

const DEFAULT_FORM_VALUES: BrandingFormValues = {
  headerStyle: 'LOGO',
  headerFont: 'sans-serif',
  bodyFont: 'sans-serif',
  footerContent: '',
  footerAlignment: 'center',
  horizontalMargin: '16',
  logoAlignment: 'left',
  logoUrl: '',
  logoSize: '124',
  showTableBorders: 'false',
};

export const BrandedDocumentSettings = () => {
  usePageClasses('bg-pageGray');

  const {
    data,
    loading: configLoading,
    previousData: configPreviousData,
  } = useQuery<GetBrandedDocumentConfig, GetBrandedDocumentConfigVariables>(
    GET_BRANDED_DOCUMENT_CONFIG_QUERY
  );

  if (configLoading && !configPreviousData) {
    return <Spinner />;
  }
  const configData = (data || configPreviousData)?.getBrandedDocumentConfig;

  const PDFContent = [convertHtmlToReactPdfContent(DUMMY_PDF_PREVIEW_DATA)]
    .filter(notNullish)
    .flat();

  return (
    <div className="flex h-screen overflow-hidden">
      <BrandedDocumentConfigForm configData={configData} />
      <div className="flex h-full flex-1 flex-col items-center">
        <PDFViewer className="h-full w-full" showToolbar={false}>
          <BrandedPdfDocument content={PDFContent} config={configData ?? DEFAULT_BRANDING_CONFIG} />
        </PDFViewer>
      </div>
    </div>
  );
};

const BrandedDocumentConfigForm: FC<{
  configData: GetBrandedDocumentConfig['getBrandedDocumentConfig'];
}> = ({ configData }) => {
  const { getFileUploadDestination, uploadFile } = useFileUpload();
  const prevFormValuesRef = useRef<BrandingFormValues | null>(null);
  const { setTrue: showLogoConfigs, setFalse: hideLogoConfigs } = useBoolean(false);

  const CONFIG_FORM_VALUES: BrandingFormValues = {
    headerStyle: configData?.headerStyle || 'LOGO',
    headerFont: (configData?.fontConfig?.header || 'sans-serif') as 'sans-serif' | 'serif' | 'mono',
    bodyFont: (configData?.fontConfig?.body || 'sans-serif') as 'sans-serif' | 'serif' | 'mono',
    footerContent: configData?.footerConfig?.content || '',
    footerAlignment: (configData?.footerConfig?.alignment || 'center') as
      | 'left'
      | 'center'
      | 'right',
    horizontalMargin: configData?.marginsConfig?.horizontal?.toString() || '40',
    logoAlignment: (configData?.logoConfig?.alignment || 'left') as 'left' | 'center' | 'right',
    logoSize: (configData?.logoConfig?.size || '124') as '64' | '124' | '224',
    logoUrl: configData?.logoConfig?.image?.src || '',
    showTableBorders: (configData?.showTableBorders?.toString() || 'false') as 'true' | 'false',
  };

  const formMethods = useForm<BrandingFormValues>({
    resolver: zodResolver(brandingFormSchema),
    defaultValues: CONFIG_FORM_VALUES ?? DEFAULT_FORM_VALUES,
  });

  const hasLogo = Boolean(configData?.logoConfig?.image?.src);
  const hasFooter = Boolean(configData?.footerConfig?.content);

  const [upsertLogoImage] = useMutation<UpsertLogoImage, UpsertLogoImageVariables>(
    UPSERT_LOGO_IMAGE_MUTATION,
    {
      refetchQueries: [GET_BRANDED_DOCUMENT_CONFIG_QUERY],
    }
  );

  const [updateConfig] = useMutation<
    UpdateBrandedDocumentConfig,
    UpdateBrandedDocumentConfigVariables
  >(UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION);

  const [deleteLogoImage] = useMutation<DeleteLogoImage, DeleteLogoImageVariables>(
    DELETE_LOGO_IMAGE_MUTATION,
    {
      refetchQueries: [GET_BRANDED_DOCUMENT_CONFIG_QUERY],
    }
  );

  const handleFileUpload = async (file: File) => {
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      toast.error(`File size should not exceed ${maxSizeInMB} MB.`);
      return;
    }

    const fileRecord = await getFileUploadDestination();
    try {
      const { url, blobName } = await uploadFile(
        file,
        fileRecord?.data?.getFileUploadLocation?.url
      );

      upsertLogoImage({
        variables: {
          input: {
            fileUrl: url,
            mimeType: file.type,
            name: file.name,
            alignment: 'left',
            blobName,
          },
        },
      });
      showLogoConfigs();
    } catch (uploadError) {
      console.error('Upload error:', uploadError);
      toast.error('Failed to upload file. Please try again.');
    }
  };

  const handleFileRemove = async () => {
    const response = await deleteLogoImage({
      variables: { fileId: configData?.logoConfig?.image?.id ?? '' },
    });
    hideLogoConfigs();
    return response;
  };

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);

  useEffect(() => {
    const prevFormValues = prevFormValuesRef.current;
    const formValuesChanged =
      JSON.stringify(debouncedFormValues) !== JSON.stringify(prevFormValues);

    if (formValuesChanged) {
      const {
        headerStyle,
        headerFont,
        bodyFont,
        footerContent,
        footerAlignment,
        horizontalMargin,
        logoAlignment,
        logoSize,
        showTableBorders,
      } = debouncedFormValues;

      const input = {
        headerStyle,
        logo: {
          alignment: logoAlignment,
          size: logoSize,
        },
        font: {
          header: headerFont,
          body: bodyFont,
        },
        margins: {
          horizontal: horizontalMargin,
        },
        footer: {
          content: footerContent,
          alignment: footerAlignment,
        },
        showTableBorders: showTableBorders === 'true',
      };

      updateConfig({ variables: { input: input } });
      prevFormValuesRef.current = debouncedFormValues;
    }
  }, [debouncedFormValues, updateConfig]);

  return (
    <Form<BrandingFormValues>
      formMethods={formMethods}
      className="flex w-1/2 flex-col gap-y-6 overflow-auto px-12 py-5"
    >
      <Link
        size="medium"
        onClick={() => navigate(routes.settingsOrganisation())}
        LeftIcon={ArrowLeftIcon}
        className="text-sm font-normal text-text-veryDark"
      >
        Back to settings
      </Link>
      <div className="flex flex-col justify-center gap-y-1">
        <h1 className="text-2xl font-bold text-text-dark">Branding</h1>
        <p className="text-sm font-normal text-text-medium">
          Customise AdScribe documents to match your company brand. Changes will be saved
          automatically.
        </p>
      </div>

      <div className="flex flex-col justify-center space-y-3">
        <h2 className="text-lg font-bold text-text-dark">Header</h2>
        <FileInput
          name="logoUrl"
          onChange={handleFileUpload}
          onRemove={handleFileRemove}
          label="Logo"
          imageUrl={configData?.logoConfig?.image?.src ?? ''}
        />
        {hasLogo && (
          <div className="flex flex-col justify-center space-y-3">
            <div className="max-w-[263px]">
              <SelectField
                name="logoSize"
                label="Size"
                options={logoSizeOptions}
                defaultValue={formMethods.getValues('logoSize')}
              />
            </div>
            <AlignmentSelectionGroup
              name="logoAlignment"
              options={alignmentOptions}
              label="Alignment"
            />
          </div>
        )}
      </div>
      <div className="flex max-w-[263px] flex-col justify-center space-y-3">
        <h2 className="text-lg font-bold text-text-dark">Font</h2>
        <FontSelectionGroup name="headerFont" options={fontOptions} label="Heading" />
        <FontSelectionGroup name="bodyFont" options={fontOptions} label="Body" />
      </div>
      <div className="flex flex-col justify-center space-y-3">
        <h2 className="text-lg font-bold text-text-dark">Footer</h2>
        <TextAreaField
          name="footerContent"
          label="Content"
          className="min-h-[120px] max-w-[424px]"
        />
        {hasFooter && (
          <AlignmentSelectionGroup
            name="footerAlignment"
            options={alignmentOptions}
            label="Alignment"
          />
        )}
      </div>
      <div className="flex flex-col justify-center space-y-3">
        <h2 className="text-lg font-bold text-text-dark">Margin</h2>
        <div className="max-w-[236px]">
          <SelectField
            name="horizontalMargin"
            label="Horizontal"
            options={marginOptions}
            defaultValue={formMethods.getValues('horizontalMargin')}
          />
        </div>
      </div>
      {/* <div className="flex flex-col space-y-3">
    <h2 className="text-lg font-bold text-text-dark">Tables</h2>
    <p className="text-sm font-normal text-text-medium">
      Tables will usually only appear in Candidate Profiles.
    </p>
    <div className="max-w-[236px]">
      <SelectField
        name="showTableBorders"
        label="Show table borders?"
        options={tableBorderOptions}
        defaultValue={formMethods.getValues('showTableBorders')}
      />
    </div>
  </div> */}
    </Form>
  );
};
