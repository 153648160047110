import { FC } from 'react';
import { InputFieldProps, useFormContext } from '@redwoodjs/forms';
import { ZodSchema } from 'zod';
import { isFieldRequired } from 'src/lib/zod';
import { useReadOnlyForm } from 'src/hooks';
import { classNames } from '../../lib';
import { Spinner } from '../Spinner';
import { ReadOnlyField } from '../ReadOnlyField';
import { Label } from '../Label';
import { FieldError } from '../FieldError';

export const textInputClasses =
  'min-w-[150px] w-full rounded-xl border disabled:bg-gray-100 border-gray-300 pl-3 py-2 text-text-veryDark placeholder:text-text-medium focus:border-primary-dark focus:outline-none pr-5';

export type TextInputProps = InputFieldProps & {
  label?: string;
  placeholder?: string;
  helpText?: string;
  /**
   * If true, the label will be large and bold.
   */
  largeLabel?: boolean;
  loading?: boolean;
  schema?: ZodSchema;
};

export const TextField: FC<TextInputProps> = ({
  name,
  helpText,
  label,
  largeLabel,
  placeholder,
  loading,
  schema,
  required,
  ...inputFieldProps
}) => {
  const formContext = useFormContext();

  const { readOnly } = useReadOnlyForm();
  const { watch } = useFormContext();

  const isDisabled = inputFieldProps.disabled || loading;
  const readOnlyValue = watch(name);
  const isRequired = schema ? isFieldRequired(schema, name) : required;
  const hasError = formContext?.formState.errors?.[name];

  if (readOnly) {
    return <ReadOnlyField name={name} label={label} value={readOnlyValue} />;
  }

  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex justify-between">
        <Label
          required={isRequired}
          name={name}
          label={label || ' '}
          readOnly={readOnly}
          largeLabel={largeLabel}
        />
      </div>
      <div className="relative flex flex-col">
        <TextInput
          {...inputFieldProps}
          placeholder={placeholder}
          className={classNames(
            textInputClasses,
            loading && 'pr-10',
            inputFieldProps.className,
            hasError && 'border-error-light text-error-medium disabled:bg-gray-100'
          )}
          disabled={isDisabled}
          {...formContext.register(name, {
            valueAsNumber: inputFieldProps.type === 'number',
            setValueAs: (value) => (value ? value.trim() : value),
          })}
        />
        {helpText && <p className="text-sm text-text-medium">{helpText}</p>}
        {loading && (
          <div className="absolute right-3 top-2">
            <Spinner className="h-6 w-6" />
          </div>
        )}
      </div>
      <FieldError name={name} />
    </div>
  );
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  { name, placeholder, loading, className, ...inputFieldProps }: TextInputProps,
  ref
) {
  const isDisabled = inputFieldProps.disabled || loading;

  return (
    <input
      {...inputFieldProps}
      ref={ref}
      placeholder={placeholder}
      className={classNames(textInputClasses, loading && 'pr-10', className)}
      name={name}
      disabled={isDisabled}
    />
  );
});
