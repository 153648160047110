import { FC } from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

type FontOption = {
  label: string;
  value: string;
  sampleText: string;
};

interface FontSelectionGroupProps extends UseControllerProps {
  options: FontOption[];
  label?: string;
}

export const FontSelectionGroup: FC<FontSelectionGroupProps> = ({ name, options, label }) => {
  const { control } = useFormContext();

  const getFontClass = (fontName: string) => {
    switch (fontName) {
      case 'serif':
        return 'font-serif';
      case 'sans-serif':
        return 'font-sans';
      case 'mono':
        return 'font-mono';
      default:
        return 'font-sans';
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      {label && <h3 className="text-sm font-medium">{label}</h3>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className="flex space-x-4">
            {options.map((option) => (
              <button
                key={option.label}
                className={`min-w-[100px] rounded-lg border p-2 ${getFontClass(option.value)} ${
                  value === option.value
                    ? 'border-text-medium   bg-gray-50 '
                    : 'border-text-light bg-white'
                }`}
                onClick={() => onChange(option.value)}
                aria-selected={value === option.value}
              >
                <div className="flex flex-col justify-center space-y-1">
                  <p className="text-3xl font-normal text-text-medium">{option.sampleText} </p>
                  <p className="text-xs font-normal text-text-medium">{option.label}</p>
                </div>
              </button>
            ))}
          </div>
        )}
      />
    </div>
  );
};
