import { z } from 'zod';

export const brandingFormSchema = z.object({
  headerStyle: z.enum(['LOGO', 'LETTERHEAD']).default('LOGO'),
  logoAlignment: z.enum(['left', 'center', 'right']).default('left'),
  headerFont: z.enum(['sans-serif', 'serif', 'mono']).default('sans-serif'),
  bodyFont: z.enum(['sans-serif', 'serif', 'mono']).default('sans-serif'),
  footerContent: z.string().nullish(),
  footerAlignment: z.enum(['left', 'center', 'right']).default('center'),
  horizontalMargin: z.string(),
  logoUrl: z.string().optional(),
  logoSize: z.enum(['64', '124', '224']).default('124'),
  showTableBorders: z.enum(['true', 'false']).default('false'),
});

export type BrandingFormValues = z.infer<typeof brandingFormSchema>;
