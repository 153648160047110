import { Bars3BottomLeftIcon, Bars3BottomRightIcon, Bars3Icon } from '@heroicons/react/24/outline';

export const fontOptions = [
  { label: 'Default', value: 'sans-serif', sampleText: 'Ay' },
  { label: 'Serif', value: 'serif', sampleText: 'Ay' },
  { label: 'Mono', value: 'mono', sampleText: 'Ay' },
];

export const alignmentOptions = [
  { icon: Bars3BottomLeftIcon, value: 'left' },
  { icon: Bars3Icon, value: 'center' },
  { icon: Bars3BottomRightIcon, value: 'right' },
];

export const marginOptions = [
  { label: 'Narrow', value: '40' },
  { label: 'Standard', value: '72' },
  { label: 'Wide', value: '112' },
];

export const logoSizeOptions = [
  { label: 'Small', value: '64' },
  { label: 'Medium', value: '124' },
  { label: 'Large', value: '224' },
];

export const tableBorderOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

// htmldocument then innerhtml it
export const DUMMY_PDF_PREVIEW_DATA = new DOMParser().parseFromString(
  `<html><body><h1>Join Our Team as a Recruitment Consultant at AdScribe</h1><p>Are you passionate about matching the right talent with the right opportunity? Do you have a knack for understanding people's strengths and how they fit into an organization? AdScribe, a pioneering recruitment AI company, is looking for a dynamic Recruitment Consultant to join our team.</p><ol><li>Identify and attract top talent using AdScribe’s innovative AI-powered recruitment tools.</li><li>Develop and maintain strong relationships with clients and candidates, ensuring a smooth recruitment process from start to finish.</li></ol><p>As a Recruitment Consultant at AdScribe, you will leverage our cutting-edge technology to streamline the hiring process, providing exceptional service to both clients and candidates. Your responsibilities will include:</p><ul><li>Conducting thorough interviews and assessments to understand candidate skills, experience, and career goals.</li><li>Collaborating with clients to understand their hiring needs and providing tailored recruitment solutions.</li></ul><p>If you are enthusiastic about the intersection of technology and recruitment, and are ready to make a significant impact in the industry, we want to hear from you!</p></body></html>`,
  'text/html'
).body.innerHTML;
