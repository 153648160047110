import {
  ArrowPathIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@redwoodjs/web';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  ChangeDocumentStyleMutation,
  ChangeDocumentStyleMutationVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  Permission,
  RegenerateDocument,
  RegenerateDocumentVariables,
} from 'types/graphql';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { useDialog, useEditDocument } from '../../hooks';

import { CampaignEditorTopBar } from '../CampaignEditorTopBar';
import { IconButton } from '../IconButton';
import { RichTextEditor } from '../RichTextEditor';
import type { RichTextEditorRef } from '../RichTextEditor';
import { Spinner } from '../Spinner';
import { canDeleteDocument, DocumentType } from '../../lib/document';
import { DocumentTitleEditor } from '../DocumentTitleEditor';
import { navigate, routes } from '@redwoodjs/router';
import { GET_JOB_AND_DOCUMENTS } from '../../graphql/queries/getJobAndDocumentsQuery';
import {
  GET_CANDIDATE_AND_DOCUMENTS_QUERY,
  GET_COMPANY_AND_DOCUMENTS_QUERY,
} from '../../graphql/queries';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  CHANGE_DOCUMENT_STYLE_MUTATION,
  REGENERATE_DOCUMENT_MUTATION,
} from '../../graphql/mutations';
import { DropdownButton } from '../DropdownButton';
import { DeleteDocumentDialog } from '../DeleteDocumentDialog';
import { notNullish } from '../../lib/guards';
import { ChangeStyleDialog } from './ChangeStyleDialog';
import { DocumentHistoryDialog } from '../DocumentHistoryDialog';
import { FadeIn } from '../FadeIn';
import { AccessControl } from '../AccessControl';
import { hasMultipleEditors, hasRequiredAccess } from 'src/lib/accessControl';
import { copyToClipboard } from 'src/lib';
import { EditorEvents } from '@tiptap/react';

type Props = {
  id: string;
  campaignEditorTopBarProps?: {
    enableSelect?: boolean;
    onSelect?: (type: DocumentType) => void;
    type: DocumentType;
    selectableDocTypes?: DocumentType[];
  };
  selectedDocumentType?: DocumentType;
};

export const DocumentEditor: FC<Props> = ({
  id,
  campaignEditorTopBarProps,
  selectedDocumentType,
}) => {
  const { show, close } = useDialog();
  const [hasUserEdited, setHasUserEdited] = useState(false);

  const {
    data: documentData,
    previousData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GET_DOCUMENT_QUERY, {
    variables: { id },
    skip: !id,
    // Return base document while history is fetched in background
    returnPartialData: true,
  });

  const richTextEditorRef = useRef<RichTextEditorRef>(null);

  const multipleEditors = hasMultipleEditors(documentData?.document?.permissions ?? []);

  const { updateDocument, loading: editDocumentLoading, isPending } = useEditDocument(id);

  useEffect(() => {
    if (multipleEditors) {
      startPolling(5000);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [multipleEditors]);

  // Reset user edit flag on campaign tab selection to prevent unintended document updates when switching between them.
  useEffect(() => {
    setHasUserEdited(false);
  }, [selectedDocumentType]);

  useEffect(() => {
    // Ensure there's document data and the editor ref is initialized
    if (documentData?.document?.markup && richTextEditorRef.current) {
      const editorContent = richTextEditorRef.current.getContent();
      const documentMarkup = documentData.document.markup;

      // Update the editor content if the current content differs from the latest document data, and there are no pending updates.
      if (editorContent !== documentMarkup && !(editDocumentLoading || isPending)) {
        richTextEditorRef.current.setContent(documentMarkup);
      }
    }
  }, [documentData, editDocumentLoading, isPending]);

  const myPermission = documentData?.document?.myPermission;

  const documentPermissions = documentData?.document?.permissions;

  const [regenerate, { loading: isRegenerating }] = useMutation<
    RegenerateDocument,
    RegenerateDocumentVariables
  >(REGENERATE_DOCUMENT_MUTATION, {
    variables: { input: { id } },
    onCompleted: (result) => {
      richTextEditorRef.current?.setContent(result.regenerateDocument.markup);
      toast.success('Document regenerated.');
    },
  });

  const [changeDocumentStyle, { loading: isChangingStyle }] = useMutation<
    ChangeDocumentStyleMutation,
    ChangeDocumentStyleMutationVariables
  >(CHANGE_DOCUMENT_STYLE_MUTATION, {
    refetchQueries: [
      GET_JOB_AND_DOCUMENTS,
      GET_COMPANY_AND_DOCUMENTS_QUERY,
      GET_CANDIDATE_AND_DOCUMENTS_QUERY,
      {
        query: GET_DOCUMENT_QUERY,
        variables: {
          id,
        },
      },
    ],
    onCompleted: (data) => {
      const updatedMarkup = data.changeDocumentStyle.markup;
      richTextEditorRef.current?.setContent(updatedMarkup);
      toast.success('Document updated with desired style!');
    },
  });

  const document = (documentData ?? previousData)?.document;

  // Trigger document update only if there's a manual edit from user, optimizing network usage.
  useEffect(() => {
    if (hasUserEdited) {
      const currentContent = richTextEditorRef?.current?.getContent();
      if (currentContent) {
        updateDocument(currentContent as unknown as EditorEvents['update']);
      }
    }
  }, [hasUserEdited, updateDocument]);

  const handleCopyDocumentMarkup = () => {
    copyToClipboard(document?.markup ?? '');
  };

  const handleCopyDocumentLink = async () => {
    const urlToCopy = `${window.location.origin}/document/${document?.id}`;
    await copyToClipboard(urlToCopy);
  };

  const handleSnippetClick = (htmlContent: string) => {
    if (richTextEditorRef.current) {
      const editor = richTextEditorRef.current.getEditor();
      editor?.chain().focus().insertContent(htmlContent).run();
    }
  };

  /* Handles changes in the rich text editor. It updates the document with the
   new content and sets the hasUserEdited flag to true. This flag is used to
   distinguish between manual user edits and programmatic changes to the editor's
   content, ensuring that the updateDocument mutation is only called in response
   to user actions. */
  const handleEditorChange = useCallback((event: EditorEvents['update']) => {
    updateDocument(event);
    setHasUserEdited(true);
  }, []);

  const onRestoreDocument = (markup: string) => {
    richTextEditorRef.current?.setContent(markup);
  };

  const onChangeStyle = (
    config: Record<string, unknown>,
    saveDefault?: boolean,
    templateId?: string
  ) => {
    if (!(document?.config && document?.id)) {
      console.error('Tried to change a document style without a config or docId');
      return;
    }

    changeDocumentStyle({
      variables: {
        input: {
          config: JSON.stringify(config),
          templateId: templateId ?? document.config.id,
          id: document.id,
          default: saveDefault ?? undefined,
        },
      },
    });
    close();
  };

  if (loading && !document) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (!document) {
    return null;
  }

  const canDelete = canDeleteDocument(document.__typename);
  const showRegenerateButton = document.__typename !== 'General';

  return (
    <div className="relative flex flex-1 flex-col overflow-hidden">
      <RichTextEditor
        editable={hasRequiredAccess(myPermission, 'WRITE')}
        ref={richTextEditorRef}
        topBar={
          campaignEditorTopBarProps ? (
            <>
              <CampaignEditorTopBar
                {...campaignEditorTopBarProps}
                onChangeStyle={
                  document?.config?.configSchema
                    ? () =>
                        show(
                          <ChangeStyleDialog
                            document={document}
                            template={document.config}
                            onChangeStyle={onChangeStyle}
                            onClose={close}
                          />
                        )
                    : undefined
                }
                myPermission={myPermission}
                selectedDocumentType={selectedDocumentType}
                documentId={document.id}
                permissionsData={documentPermissions as Permission[]}
                onCopy={handleCopyDocumentLink}
              />
            </>
          ) : (
            <>
              <div className="flex flex-row items-center">
                <DocumentTitleEditor document={document} />
                <DropdownButton
                  options={[
                    {
                      Icon: ChatBubbleLeftIcon,
                      text: 'Edit with Chat',
                      onClick: () => navigate(routes.chat({ documentId: document.id })),
                    },
                    canDelete
                      ? {
                          text: 'Delete',
                          onClick: () =>
                            show(
                              <DeleteDocumentDialog
                                onDismiss={close}
                                document={document}
                                navigateTo={routes.documents()}
                              />
                            ),
                          Icon: TrashIcon,
                        }
                      : null,
                  ].filter(notNullish)}
                />
              </div>
            </>
          )
        }
        content={document.markup}
        onChange={handleEditorChange}
        onHandleSnippetClick={handleSnippetClick}
        myPermission={myPermission}
        extraButtons={
          <>
            <AccessControl myPermission={myPermission} requiredPermission="WRITE">
              <FadeIn visible>
                <IconButton
                  tooltipText="History"
                  size="medium"
                  Icon={ClockIcon}
                  onClick={() =>
                    show(
                      <DocumentHistoryDialog
                        onRestore={onRestoreDocument}
                        onClose={close}
                        documentId={id}
                      />
                    )
                  }
                />
              </FadeIn>
            </AccessControl>
            <AccessControl myPermission={myPermission} requiredPermission="OWNER">
              <FadeIn visible={showRegenerateButton}>
                <IconButton
                  tooltipText="Regenerate"
                  size="medium"
                  Icon={ArrowPathIcon}
                  onClick={regenerate}
                />
              </FadeIn>
            </AccessControl>

            <IconButton
              tooltipText="Copy to Clipboard"
              size="medium"
              Icon={DocumentDuplicateIcon}
              onClick={handleCopyDocumentMarkup}
            />
          </>
        }
      />
      {(isRegenerating || isChangingStyle) && (
        <div className="absolute inset-0 rounded-[36px] bg-white opacity-80">
          <Spinner />
        </div>
      )}
    </div>
  );
};
