import {
  MegaphoneIcon,
  ChatBubbleBottomCenterTextIcon,
  HashtagIcon,
  EnvelopeIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  MagnifyingGlassCircleIcon,
  BoltIcon,
  UserIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Document, Maybe } from '../../types/graphql';
import colors from '../assets/colors';

export type DocumentType = Document['__typename'];

export const isTemplateDoc = (doc: Maybe<Pick<Document, '__typename'>>): boolean =>
  doc?.__typename === 'TestimonialRequest' ||
  doc?.__typename === 'BlogPost' ||
  doc?.__typename === 'BlogPostIdeas' ||
  doc?.__typename === 'MarketingStrategy' ||
  doc?.__typename === 'ClientProposal' ||
  doc?.__typename === 'ClientOutreach';

// TODO: Use the 'TemplateDocument' handler when we get the chance.
export function canDeleteDocument(type: DocumentType) {
  return type === 'General' || isTemplateDoc({ __typename: type });
}

export const JOB_CAMPAIGN_CONFIGURABLE_DOCUMENTS: readonly DocumentType[] = [
  'JobAdvert',
  'JobSocialPost',
  'JobInMail',
  'JobSnapshot',
] as const;

export const CANDIDATE_CAMPAIGN_CONFIGURABLE_DOCUMENTS: readonly DocumentType[] = [
  'CandidateIntroduction',
  'CandidateSnapshot',
  // 'CandidateCv',
] as const;
export const COMPANY_CAMPAIGN_CONFIGURABLE_DOCUMENTS: readonly DocumentType[] = [
  'CompanyIntroduction',
] as const;

export const documentPresentationalProperties: Record<
  DocumentType,
  {
    Icon: FC<React.ComponentProps<'svg'>>;
    color: string;
    title: string;
  }
> = {
  JobAdvert: {
    Icon: MegaphoneIcon,
    color: colors.jobAd.medium,
    title: 'Job Advert',
  },
  JobInMail: {
    Icon: ChatBubbleBottomCenterTextIcon,
    color: colors.inMail.medium,
    title: 'In-Mail',
  },
  JobEmail: {
    Icon: EnvelopeIcon,
    color: colors.email.medium,
    title: 'Email',
  },
  JobSocialPost: {
    Icon: HashtagIcon,
    color: colors.social.medium,
    title: 'Social Post',
  },
  JobSnapshot: {
    Icon: EnvelopeIcon,
    color: colors.email.medium,
    title: 'Snapshot',
  },
  JobInterviewPreparation: {
    Icon: ClipboardDocumentIcon,
    color: colors.jobAd.medium,
    title: 'Interview Prep',
  },
  JobReferenceCheck: {
    Icon: ClipboardDocumentCheckIcon,
    color: colors.jobAd.medium,
    title: 'Reference Check',
  },
  JobInterviewQuestions: {
    Icon: ClipboardDocumentListIcon,
    color: colors.jobAd.medium,
    title: 'Interview Questions',
  },
  JobBooleanSearch: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Boolean Search',
  },
  CandidateSnapshot: {
    Icon: BoltIcon,
    color: colors.jobAd.medium,
    title: 'Summary',
  },
  CandidateIntroduction: {
    Icon: UserIcon,
    color: colors.inMail.medium,
    title: 'Introduction',
  },
  CandidateCv: {
    Icon: UserIcon,
    color: colors.inMail.medium,
    title: 'CV',
  },
  CompanySnapshot: {
    Icon: BoltIcon,
    color: colors.jobAd.medium,
    title: 'Company Snapshot',
  },
  CompanyIntroduction: {
    Icon: BuildingOfficeIcon,
    color: colors.inMail.medium,
    title: 'Introduction',
  },
  ClientProposal: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Client Proposal',
  },
  BlogPost: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Blog Post',
  },
  BlogPostIdeas: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Blog Post Ideas',
  },
  TestimonialRequest: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Testimonial Request',
  },
  MarketingStrategy: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Marketing Strategy',
  },
  General: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'General',
  },
  ClientOutreach: {
    Icon: MagnifyingGlassCircleIcon,
    color: colors.jobAd.medium,
    title: 'Client Outreach',
  },
};

const documentTagColors = [
  'bg-tags-1',
  'bg-tags-2',
  'bg-tags-3',
  'bg-tags-4',
  'bg-tags-5',
  'bg-tags-6',
  'bg-tags-7',
  'bg-tags-8',
  'bg-tags-9',
  'bg-tags-10',
];

const documentTagColorMap = new Map<string, string>();

export const getDocumentTagColor = (tag: string) => {
  if (!documentTagColorMap.has(tag)) {
    documentTagColorMap.set(
      tag,
      documentTagColors[documentTagColorMap.size % documentTagColors.length]
    );
  }
  return documentTagColorMap.get(tag);
};
