import { Menu, MenuButton } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  ChatBubbleLeftIcon,
  ChevronUpDownIcon,
  PaintBrushIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { navigate, routes, useLocation } from '@redwoodjs/router';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { classNames } from '../../lib';
import { documentPresentationalProperties, DocumentType } from '../../lib/document';
import { notNullish } from '../../lib/guards';
import { DocumentTypeDropdown } from '../DocumentEditor';
import { DropdownButton } from '../DropdownButton';
import { useDialog } from 'src/hooks';
import { DocumentExportDialog } from '../ExportDialogs/DocumentExportDialog';
import { ACCESS_LEVEL, Permission } from 'types/graphql';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { NonOwnerViewDialog, ShareDocumentDialog } from '../ShareDialogs';
import { OtherPermissions } from '../OtherPermissions';

export const CampaignEditorTopBar: FC<{
  enableSelect?: boolean;
  onSelect?: (type: DocumentType) => void;
  type: DocumentType;
  selectableDocTypes?: DocumentType[];
  documentId: string;
  onChangeStyle?: (id: string) => void;
  myPermission?: ACCESS_LEVEL | null;
  selectedDocumentType?: DocumentType;
  permissionsData: Permission[];
  onCopy?: () => void;
}> = ({
  enableSelect,
  onSelect,
  type,
  selectableDocTypes,
  documentId,
  onChangeStyle,
  myPermission,
  selectedDocumentType,
  permissionsData,
  onCopy,
}) => {
  const { Icon, color, title } = documentPresentationalProperties[type];

  const { pathname } = useLocation();

  const selectedDocumentTitle = selectedDocumentType
    ? documentPresentationalProperties[selectedDocumentType]?.title || ''
    : '';

  const isOwner = hasRequiredAccess(myPermission, 'OWNER');

  const { show, close } = useDialog();

  const dropdownButtonOptions = [
    onChangeStyle && isOwner
      ? {
          onClick: () => onChangeStyle(documentId),
          Icon: PaintBrushIcon,
          text: 'Change Style',
        }
      : null,
    isOwner
      ? {
          onClick: () =>
            navigate(
              routes.chat({
                documentId: documentId,
                redirectUrl: pathname,
                ...(selectedDocumentType && {
                  documentType: selectedDocumentType,
                }),
              })
            ),
          Icon: ChatBubbleLeftIcon,
          text: 'Edit with Chat',
        }
      : null,
    isOwner
      ? {
          text: 'Share Document',
          Icon: UserPlusIcon,
          onClick: () => {
            show(
              <ShareDocumentDialog
                documentId={documentId}
                isOwner={isOwner}
                onClose={close}
                title={`Share ${selectedDocumentTitle}`}
                description={`Members with edit access are only authorised to make manual edits. This means they cannot regenerate your ${selectedDocumentTitle}`}
                onCopy={onCopy}
              />
            );
          },
        }
      : null,
    {
      text: 'Export',
      Icon: ArrowDownTrayIcon,
      onClick: () =>
        show(<DocumentExportDialog className="w-full min-w-min !max-w-[348px]" id={documentId} />),
    },
  ].filter(notNullish);

  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center justify-start">
        <Menu>
          <MenuButton
            className={classNames(
              'flex items-center gap-x-2',
              enableSelect && 'hover:cursor-pointer'
            )}
            disabled={!enableSelect}
          >
            <span>
              <Icon className={classNames('h-4 w-4')} style={{ color }} />
            </span>
            <h3 className="text-text-dark">{title}</h3>
            {enableSelect && (
              <AnimatePresence>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <ChevronUpDownIcon className="h-4 w-4" />
                </motion.div>
              </AnimatePresence>
            )}
          </MenuButton>

          <DocumentTypeDropdown
            options={selectableDocTypes ?? []}
            onSelect={(type: DocumentType) => (onSelect ? onSelect(type) : undefined)}
            selected={type}
          />
        </Menu>
      </div>

      <div className="flex flex-row items-center">
        <div className="mr-3 flex items-end justify-end gap-2 ">
          <OtherPermissions
            permissionsData={permissionsData}
            onClick={() => {
              isOwner
                ? show(
                    <ShareDocumentDialog
                      isOwner={isOwner}
                      documentId={documentId}
                      onClose={close}
                      title={`Share ${selectedDocumentTitle}`}
                      description={`Members with edit access are only authorised to make manual edits. This means they cannot regenerate your ${selectedDocumentTitle}`}
                      onCopy={onCopy}
                    />
                  )
                : show(
                    <NonOwnerViewDialog onClose={close} permissionsData={permissionsData ?? []} />
                  );
            }}
          />
        </div>
        <DropdownButton options={dropdownButtonOptions} />
      </div>
    </div>
  );
};
