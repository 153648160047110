import { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../lib';
import { Controller, ControllerProps, useFormContext } from '@redwoodjs/forms';
import { Spinner } from '../Spinner';
import { ReadOnlyField } from '../ReadOnlyField';
import { useReadOnlyForm } from 'src/hooks';
import { FieldError } from '../FieldError';

type Option<T> = {
  label: string;
  value: T;
};

type SelectProps<T> = {
  options: Option<T>[];
  label?: string;
  onChange: (value: T) => void;
  onBlur?: () => void;
  value?: T | null;
  name?: string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
};

export function Select<T extends string>({
  options,
  label,
  value,
  onChange,
  onBlur,
  name,
  placeholder,
  loading,
  disabled,
}: SelectProps<T>) {
  const valueLabel = options.find((option) => option.value === value)?.label;
  const presentedPlaceholder = placeholder || 'Select';
  const isDisabled = loading || disabled;
  return (
    <Listbox name={name} value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="w-full">
            {label && (
              <Listbox.Label className="block text-sm font-medium text-text-dark">
                {label}
              </Listbox.Label>
            )}
            <div className="relative mt-1">
              <ListboxButton
                disabled={isDisabled}
                onBlur={onBlur}
                className={classNames(
                  'relative w-full min-w-[150px] cursor-default rounded-xl border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-text-veryDark shadow-sm focus:border-primary-medium focus:outline-none focus:ring-1 focus:ring-primary-medium disabled:bg-gray-100',
                  loading && 'bg-gray-100'
                )}
              >
                <span className="block truncate">{valueLabel || presentedPlaceholder}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              {loading && (
                <div className="absolute right-7 top-2">
                  <Spinner className="h-6 w-6" />
                </div>
              )}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {options.map((option) => (
                    <ListboxOption
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-primary-medium text-white' : 'text-text-dark',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-primary-medium',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
}

export function SelectField<T>({
  name,
  defaultValue,
  label,
  ...rest
}: Omit<ControllerProps, 'render'> & { label: string; name: string } & Pick<
    SelectProps<T>,
    'placeholder' | 'options' | 'onBlur' | 'loading'
  >) {
  const { readOnly } = useReadOnlyForm();
  const { watch } = useFormContext();
  const readOnlyValue = watch(name);

  return readOnly ? (
    <ReadOnlyField name={name} label={label} value={readOnlyValue} />
  ) : (
    <div className="relative">
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Select
            {...rest}
            name={name}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <FieldError name={name} className="block pt-2" />
    </div>
  );
}
