import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { FC, memo } from 'react';
import { DocumentBrandingConfig } from 'types/graphql';
import { PdfNode as TPdfNode } from './PdfNodes/types';
import { PdfNode } from './PdfNodes';

type BrandedDocumentProps = {
  content: TPdfNode[];
  config: DocumentBrandingConfig;
};

Font.register({
  family: 'serif',
  fonts: [
    { src: '/fonts/LibreBaskerville-Regular.ttf' },
    { src: '/fonts/LibreBaskerville-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/LibreBaskerville-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'sans-serif',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/Roboto-Black.ttf', fontWeight: 900 },
    { src: '/fonts/Roboto-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'mono',
  fonts: [
    { src: '/fonts/RobotoMono-Regular.ttf' },
    { src: '/fonts/RobotoMono-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/RobotoMono-Italic.ttf', fontStyle: 'italic' },
  ],
});

const getAlignment = (alignment: string): 'flex-start' | 'center' | 'flex-end' => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
    default:
      return 'center';
  }
};

const BrandedPdfDocument: FC<BrandedDocumentProps> = ({ content, config }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
      flexDirection: 'column',
      paddingBottom: 84,
      paddingHorizontal: parseInt(config?.marginsConfig?.horizontal ?? '10', 10),
      fontSize: 12,
      // Keep some extra when no logo is present too (36)
    },
    headerWrapper: {
      paddingTop: 40,
      paddingBottom: 16,
    },
    pageBody: {
      paddingBottom: 10,
    },
    logo: {
      width: `${config?.logoConfig?.size ?? 124}pt`,
      alignSelf: getAlignment(config?.logoConfig?.alignment ?? 'left'),
    },
    letterhead: {
      width: '100%',
      height: 'auto',
      alignSelf: getAlignment(config?.letterheadConfig?.alignment ?? 'left'),
    },
    bodyText: {
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
      fontSize: 12,
      lineHeight: 1.5,
      paddingBottom: 8,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: parseInt(config?.marginsConfig?.horizontal ?? '16', 10),
      right: parseInt(config?.marginsConfig?.horizontal ?? '16', 10),
      textAlign: (config?.footerConfig?.alignment as 'left' | 'center' | 'right') ?? 'center',
      fontSize: 9,
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerWrapper} fixed>
          {config?.headerStyle === 'LOGO' && config?.logoConfig?.image?.src && (
            <Image src={config?.logoConfig?.image?.src} style={styles.logo} />
          )}
          {config?.headerStyle === 'LETTERHEAD' && config?.letterheadConfig?.image?.src && (
            <Image src={config?.letterheadConfig?.image?.src} style={styles.letterhead} />
          )}
        </View>
        <View style={styles.pageBody}>
          {[...(content ?? [])].map((node, index) => (
            <PdfNode key={index} node={node} documentConfig={config} />
          ))}
        </View>
        <Text style={styles.footer} fixed>
          {config?.footerConfig?.content ?? ''}
        </Text>
      </Page>
    </Document>
  );
};

export default memo(BrandedPdfDocument);
