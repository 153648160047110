import { MenuItems } from '@headlessui/react';
import { FC } from 'react';
import { DOCUMENT_TYPE } from '../../../types/graphql';
import { classNames } from '../../lib';
import { DocumentListItem } from '../DocumentListItem';

type Props = {
  selected: DOCUMENT_TYPE;
  onSelect: (type: DOCUMENT_TYPE) => void;
  options: DOCUMENT_TYPE[];
};

export const DocumentTypeDropdown: FC<Props> = ({ selected, onSelect, options }) => {
  const handleClick = (option: DOCUMENT_TYPE) => {
    if (option !== selected) {
      onSelect(option);
    }
  };
  return (
    <div className="relative">
      <MenuItems
        className={classNames(
          'absolute top-full z-10 mt-4 grid w-72  grid-cols-2 gap-3  rounded-lg bg-pageGray p-6 shadow-lg'
        )}
      >
        {options.map((option) => (
          <DocumentListItem
            type={option}
            key={option}
            selected={selected === option}
            onClick={() => handleClick(option)}
          />
        ))}
      </MenuItems>
    </div>
  );
};
