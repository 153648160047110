import { Link, routes, useLocation } from '@redwoodjs/router';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { FC, Fragment, PropsWithChildren, useMemo } from 'react';
import {
  CogIcon,
  UserGroupIcon,
  DocumentTextIcon,
  ScissorsIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { MetaTags } from '@redwoodjs/web';
import { PageTitle } from '../../components/PageTitle';
import { usePageClasses, useTrackPageView } from 'src/hooks';
import { UserAvatar } from 'src/components/UserAvatar/UserAvatar';
import { classNames } from 'src/lib';
import { SectionListItem } from 'src/pages/SettingsPages/shared';
import { useFeatureStore } from 'src/services/store';
import { notNullish } from 'src/lib/guards';

const SECTION_NAMES = [
  'Account',
  'Profile',
  'Privacy',
  'Organisation',
  'Documents',
  'Snippets',
  //TODO: add this later 'Billing',
];

type IconType = FC<React.ComponentProps<'svg'>>;

const icons: Record<string, IconType> = {
  Account: UserAvatar,
  Profile: UserIcon,
  Organisation: UserGroupIcon,
  Documents: DocumentTextIcon,
  Snippets: ScissorsIcon,
  Privacy: ShieldCheckIcon,
  Billing: CurrencyDollarIcon,
};

const SettingsLayout = ({ children }: PropsWithChildren) => {
  useTrackPageView();
  usePageClasses('bg-white');

  const { features } = useFeatureStore();
  const location = useLocation();

  // Dynamically generate the route paths inside the component
  const sections = useMemo(
    () =>
      SECTION_NAMES.map((name) => {
        if (name === 'Profile' && !features.userPersona) return null;

        return {
          name,
          route: routes[`settings${name}`](),
          Icon: icons[name],
        };
      }).filter(notNullish),
    [features]
  );

  // Determine the active section based on the current route
  const activeSection = useMemo(() => {
    const match = sections.find((section) => location.pathname === section.route);
    return match ? match.name : null;
  }, [location, sections]);

  let sectionName = new URLSearchParams(location.search).get('section');
  if (sectionName) {
    sectionName = sectionName.replace(/['"]+/g, '');
  }

  const isBrandingPage = sectionName === 'Branding';

  return (
    <div className="flex h-full w-full flex-col items-center">
      <MetaTags title="Settings" description="Settings page" />
      <div className="flex w-full flex-grow flex-col">
        {!isBrandingPage && <PageTitle className="px-10 py-4" Icon={CogIcon} text="Settings" />}

        {!isBrandingPage && (
          <TabGroup className="flex flex-grow flex-row border-t border-text-light">
            <div className="w-[190px] border-r border-text-light px-3 pt-6">
              <TabList className="flex flex-col gap-y-4">
                {sections.map((section) => (
                  <Tab key={section.name} as={Fragment}>
                    {() => (
                      <Link to={section.route}>
                        <SectionListItem selected={section.name === activeSection}>
                          <section.Icon
                            className={classNames(
                              'h-6 w-6 min-w-[24px]',
                              section.name === activeSection ? 'text-text-dark' : 'text-text-medium'
                            )}
                            strokeWidth={2}
                          />
                          <p
                            className={
                              section.name === activeSection ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {section.name}
                          </p>
                        </SectionListItem>
                      </Link>
                    )}
                  </Tab>
                ))}
              </TabList>
            </div>
            <TabPanels className="flex flex-1">
              {sections.map((section) => (
                <TabPanel key={section.name} className="w-full">
                  {children}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        )}
        {isBrandingPage && <div className="flex flex-1">{children}</div>}
      </div>
    </div>
  );
};

export default SettingsLayout;
