import { BriefcaseIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { motion } from 'framer-motion';
import { FC, PropsWithChildren, useState } from 'react';
import {
  DOCUMENT_TYPE,
  GetMyDocTemplateQueryVariables,
  GetMyDocumentTemplateQuery,
  SetDocumentTemplateMutation,
  SetDocumentTemplateMutationVariables,
  ONBOARDING_TYPE,
  UpdateUserOnboardingStatusMutation,
  UpdateUserOnboardingStatusMutationVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from '../../../types/graphql';
import { CompanyIllustration } from '../../assets/172-Diversity-&-Inclusion-Revision';
import { CandidateCvIllustration } from '../../assets/CandidateCvIllustration';
import { DesignProcess } from '../../assets/DesignProcess';
import { useAuth } from '../../auth';
import { Button } from '..';
import { DocumentConfigForm } from '../DocumentConfigForm';
import { Spinner } from '../Spinner';
import {
  SET_DOCUMENT_TEMPLATE_MUTATION,
  UPDATE_USER_ONBOARDING_STATUS_MUTATION,
} from '../../graphql/mutations';
import { GET_ME_QUERY, GET_MY_DOCUMENT_TEMPLATE_QUERY } from '../../graphql/queries';
import { classNames } from '../../lib';
import { documentPresentationalProperties } from '../../lib/document';
import { useApolloClient } from '@apollo/client';

type Props = {
  configurableDocuments: readonly DOCUMENT_TYPE[];
  onboardingType: ONBOARDING_TYPE;
};

export const CampaignOnboardingWizard: FC<Props> = ({ configurableDocuments, onboardingType }) => {
  const { cache } = useApolloClient();
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const { currentUser } = useAuth();
  const [setDocumentTemplate, { loading }] = useMutation<
    SetDocumentTemplateMutation,
    SetDocumentTemplateMutationVariables
  >(SET_DOCUMENT_TEMPLATE_MUTATION, {
    onCompleted: () => {
      console.log('Optimistic response completed');
      if (currentStep === configurableDocuments.length - 1) {
        updateUserOnboardingStatus({
          variables: {
            input: {
              onboardingType,
              status: 'COMPLETED',
            },
          },
        });
        return;
      } else {
        // Should never be null here but TS doesn't know that
        setCurrentStep((currentStep ?? 0) + 1);
      }
    },
    optimisticResponse: ({ input }) => {
      const documentTemplate = cache.readQuery<
        GetMyDocumentTemplateQuery,
        GetMyDocTemplateQueryVariables
      >({
        query: GET_MY_DOCUMENT_TEMPLATE_QUERY,
        variables: {
          myDocumentTemplateInput: {
            docType: input.docType,
          },
        },
      });
      return {
        __typename: 'Mutation',
        setDocumentTemplate: {
          id: 'optimistic',
          __typename: 'DocumentTemplate',
          title: documentTemplate?.myDocumentTemplate?.title ?? '',
          baseTemplateId: input.baseTemplateId ?? documentTemplate?.myDocumentTemplate?.id ?? '',
          description: documentTemplate?.myDocumentTemplate?.description ?? '',
          config: input.config ?? documentTemplate?.myDocumentTemplate?.config,
          configSchema: documentTemplate?.myDocumentTemplate?.configSchema,
          docType: input.docType,
        },
      };
    },
  });
  const [updateUserOnboardingStatus] = useMutation<
    UpdateUserOnboardingStatusMutation,
    UpdateUserOnboardingStatusMutationVariables
  >(UPDATE_USER_ONBOARDING_STATUS_MUTATION, {
    optimisticResponse: ({ input: { onboardingType, status } }) => {
      const user = cache.readQuery<GetMeQuery, GetMeQueryVariables>({
        query: GET_ME_QUERY,
        variables: {
          isAdmin: false,
        },
      });
      if (!user?.me) {
        throw new Error('User not found');
      }

      const baseResult = {
        __typename: 'Mutation',
        updateUserOnboardingStatus: user?.me,
      } as const;
      switch (onboardingType) {
        case 'CANDIDATE':
          return {
            ...baseResult,
            candidateCampaignOnboardingStatus: status,
          };
        case 'COMPANY':
          return {
            ...baseResult,
            companyCampaignOnboardingStatus: status,
          };
        case 'JOB':
          return {
            ...baseResult,
            jobCampaignOnboardingStatus: status,
          };
      }
    },
    onCompleted: () => {
      toast.success('Your preferences have been saved.');
    },
  });

  const onSubmit = (data: { docType: DOCUMENT_TYPE; baseTemplateId: string; config: string }) => {
    if (currentStep === null) {
      return;
    }
    setDocumentTemplate({
      variables: {
        input: data,
      },
    });
  };

  const onCancel = () => {
    updateUserOnboardingStatus({
      variables: {
        input: {
          onboardingType,
          status: 'COMPLETED',
        },
      },
    });
  };

  const onGetStarted = () => {
    setCurrentStep(0);
  };

  const onSkip = () => {
    updateUserOnboardingStatus({
      variables: {
        input: {
          onboardingType,
          status: 'COMPLETED',
        },
      },
    });
  };

  if (currentStep === null) {
    return (
      <OnboardingIntro
        onboardingType={onboardingType}
        onGetStarted={onGetStarted}
        onSkip={onSkip}
      />
    );
  }

  return (
    <FormStep
      mutationLoading={loading}
      key={currentStep}
      onSubmit={onSubmit}
      onCancel={onCancel}
      configurableDocuments={configurableDocuments}
      step={currentStep}
    />
  );
};

const OnboardingIntro: FC<{
  onGetStarted: () => void;
  onSkip: () => void;
  onboardingType: ONBOARDING_TYPE;
}> = ({ onGetStarted, onSkip, onboardingType }) => {
  const { title, description, Icon, Image } = getOnboardingTypeDetails(onboardingType);

  return (
    <FadeInOut className="flex max-w-4xl flex-1 flex-col items-center self-center">
      <div className="flex flex-1 flex-col items-center justify-center">
        <h2 className="top-24 flex pb-6 text-center text-4xl font-bold text-text-veryDark">
          <span className="px-2 ">{Icon}</span>
          {title}
        </h2>
        <div className="flex gap-x-3 pb-6">
          <div className="flex flex-col justify-center py-3">
            <p className="pb-6 text-2xl font-bold text-text-dark">{description}</p>
            <p className="pb-6 text-lg text-text-medium">
              Help AdScribe match your writing style by answering a few short questions.
            </p>
            <div className="flex flex-row gap-x-3 pb-3">
              <Button variant="outline" text="Skip" size="medium" onClick={onSkip} />
              <Button size="medium" text="Get Started" onClick={onGetStarted} />
            </div>
            <p className="text-sm text-text-medium">
              You can always set your preferences later from the settings page.
            </p>
          </div>
          <div className="flex flex-1">{Image}</div>
        </div>
      </div>
    </FadeInOut>
  );
};

function getOnboardingTypeDetails(onboardingType: ONBOARDING_TYPE): {
  title: string;
  description: string;
  Icon: JSX.Element;
  Image: JSX.Element;
} {
  switch (onboardingType) {
    case 'CANDIDATE':
      return {
        title: 'Create an Outstanding Candidate Intro',
        description: 'First, tell us a bit about your preferences',
        Icon: <UserIcon className="h-10 w-10 text-text-veryDark" />,
        Image: <CandidateCvIllustration className="h-[420px] w-[420px]" />,
      };
    case 'COMPANY':
      return {
        title: 'Create a Compelling Company Intro',
        description: 'First, tell us a bit about your preferences',
        Icon: <UserGroupIcon className="h-10 w-10 text-text-veryDark" />,
        Image: <CompanyIllustration className="h-[420px] w-[420px]" />,
      };
    case 'JOB':
      return {
        title: 'Create a Job Campaign',
        description: 'First, tell us a bit about your Recruitment style',
        Icon: <BriefcaseIcon className="h-10 w-10 text-text-veryDark" />,
        Image: <DesignProcess />,
      };
  }
}

type FormStepProps = {
  onSubmit: (data: { docType: DOCUMENT_TYPE; baseTemplateId: string; config: string }) => void;
  onCancel: () => void;
  step: number;
  mutationLoading?: boolean;
  configurableDocuments: readonly DOCUMENT_TYPE[];
};

const FormStep: FC<FormStepProps> = ({
  onSubmit,
  onCancel,
  step,
  mutationLoading,
  configurableDocuments,
}) => {
  const docType = configurableDocuments[step];
  const { data, loading } = useQuery<GetMyDocumentTemplateQuery, GetMyDocTemplateQueryVariables>(
    GET_MY_DOCUMENT_TEMPLATE_QUERY,
    {
      variables: {
        myDocumentTemplateInput: {
          docType,
        },
      },
    }
  );

  const schema = data?.myDocumentTemplate?.configSchema;
  const template = data?.myDocumentTemplate;

  return (
    <FadeInOut className="flex w-full max-w-xl flex-col self-center justify-self-center">
      <div className="flex flex-row gap-x-8 self-center pt-6">
        {configurableDocuments.map((docType, index) => (
          <Step
            key={index}
            step={index + 1}
            title={documentPresentationalProperties[docType].title}
            currentStep={step + 1}
          />
        ))}
      </div>
      <h1 className="py-8 text-2xl font-bold text-text-dark">
        {`${step + 1}. `}What style of {documentPresentationalProperties[docType].title} do you
        prefer?
      </h1>
      {loading ? (
        <Spinner />
      ) : (
        <DocumentConfigForm
          cancelText="Skip"
          loading={mutationLoading}
          submitText="Save and Continue"
          onSubmit={(data) =>
            onSubmit({
              config: JSON.stringify(data),
              docType,
              baseTemplateId: template?.id ?? '',
            })
          }
          onCancel={onCancel}
          schema={JSON.parse(schema ?? '')}
        />
      )}
    </FadeInOut>
  );
};

const Step: FC<{ step: number; title: string; currentStep: number }> = ({
  step,
  title,
  currentStep,
}) => {
  return (
    <div className="flex flex-row items-center">
      <div
        className={classNames(
          'flex h-10 w-10 items-center justify-center rounded-full text-lg font-medium',
          currentStep > step && 'bg-generate-medium text-white',
          currentStep === step && 'border border-generate-medium bg-white text-generate-medium',
          currentStep < step && 'border border-text-light bg-white text-text-light'
        )}
      >
        {step}
      </div>
      <div
        className={classNames(
          'pl-3 font-medium',
          currentStep > step && 'text-generate-medium',
          currentStep === step && 'text-text-dark',
          currentStep < step && 'text-text-light'
        )}
      >
        {title}
      </div>
    </div>
  );
};

const FadeInOut: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <motion.div
      initial={{ opacity: '0%' }}
      animate={{ opacity: '100%' }}
      exit={{ opacity: '0%' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
