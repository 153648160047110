import { DOCUMENT_VERSION_ACTIONS_FRAGMENT } from './documentVersionFragment';
import { PERMISSION_FIELDS_FRAGMENT } from './permissionFieldsFragment';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const DOCUMENT_FIELDS_WITH_HISTORY_FRAGMENT = gql`
  ${DOCUMENT_VERSION_ACTIONS_FRAGMENT}
  ${PERMISSION_FIELDS_FRAGMENT}
  fragment DocumentFieldsWithHistory on Document {
    ... on JobSocialPost {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt

      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on JobAdvert {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt

      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on JobSnapshot {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on JobInMail {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on JobEmail {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on JobInterviewQuestions {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on JobBooleanSearch {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on JobReferenceCheck {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on JobInterviewPreparation {
      id
      title
      userId
      jobId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on CandidateSnapshot {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      candidateId
      title
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on CompanySnapshot {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      companyId
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on CompanyIntroduction {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      companyId
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on CandidateIntroduction {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
      candidateId
      title
    }
    __typename
    ... on CandidateCv {
      title
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
      candidateId
      title
    }
    ... on ClientProposal {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        clientCompanyName
        yourCompanyName
        clientCompanyUSPs
        yourCompanyUSPs
      }
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on BlogPost {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        industry
        targetAudience
        topic
        type
        informationToInclude
      }
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on BlogPostIdeas {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      input {
        candidates
        clients
        industry
      }
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on MarketingStrategy {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      input {
        industry
        targetCandidates
        targetClients
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
    ... on TestimonialRequest {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      input {
        candidateName
        companyName
        recipient
        recipientName
        duration
        servicesProvided
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on General {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt
      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }

    ... on ClientOutreach {
      id
      title
      userId
      markup
      isActive
      createdAt
      updatedAt

      config {
        id
        config
        configSchema
        defaultConfig
      }
      history {
        ...DocumentVersionActions
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;
